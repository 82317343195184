














import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import Vue, { PropOptions } from 'vue';
import { Unit } from '../../models/Unit';
import { UnitFields } from '../../models/UnitFields';
import { ValueWithUnit } from '../../models/ValueWithUnit';
import { isDefined } from '../../utils/Utils';

export default Vue.extend({
  name: 'UnitHistoryListItem',

  props: {
    history: {
      type: Array,
      required: true,
      default: () => [],
    } as PropOptions<Unit[]>,
    property: {
      type: String,
      required: true,
    } as PropOptions<keyof UnitFields>,
    color: {
      type: String,
      default: undefined,
    },
  },

  watch: {
    history: {
      deep: true,
      handler() {
        this.drawChart();
      },
    },
  },

  data() {
    return {
      chart: undefined as google.visualization.LineChart | undefined,
    };
  },

  computed: {
    hasHistory(): boolean {
      const history = this.history.filter(entry =>
        isDefined(entry?.[this.property])
      );
      return history.length > 0;
    },
    propertyTranslation(): string {
      return this.$t(`unit.${this.property}`) as string;
    },
    debouncedDrawChart(): () => void {
      return debounce(this.drawChart, 500);
    },
  },

  async mounted(): Promise<void> {
    await this.$nextTick();
    this.drawChart();
    window.addEventListener('resize', this.debouncedDrawChart);
  },

  beforeDestroy(): void {
    window.removeEventListener('resize', this.debouncedDrawChart);
  },

  methods: {
    async drawChart(): Promise<void> {
      await this.$nextTick();
      this.chart?.clearChart();

      const data = new google.visualization.DataTable();
      const unitForProperty = this.history.find(
        entry => entry?.[this.property]
      );
      const property = unitForProperty?.[this.property];

      if (property instanceof ValueWithUnit) {
        const formattedUnit = property?.formattedUnit ?? '';
        data.addColumn(
          'datetime',
          this.$t('component.unitHistoryListItem.timestamp') as string
        );
        data.addColumn(
          'number',
          `${this.propertyTranslation} ${formattedUnit}`
        );
        data.addRows(
          this.history.map(unit => [
            DateTime.fromMillis(unit.timestamp ?? 0).toJSDate(),
            (unit?.[this.property] as ValueWithUnit)?.value,
          ])
        );
        const chartEl = this.$refs.chart;
        if (!this.chart && chartEl instanceof HTMLElement) {
          this.chart = new google.visualization.LineChart(chartEl);
        }

        this.chart?.draw(data, {
          pointsVisible: true,
          interpolateNulls: true,
          series: {
            0: { color: this.color },
          },
        });
      }
    },
  },
});
