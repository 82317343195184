































import Vue, { PropOptions } from 'vue';
import { Thing } from '../../models/Thing';
import { Unit } from '../../models/Unit';
import { UnitFields } from '../../models/UnitFields';
import { ThingsService } from '../../services/things/ThingsService';
import { isDefined, pickDefined } from '../../utils/Utils';
import UnitHistoryListItem from './UnitHistoryListItem.vue';

type ListItem = {
  property: Extract<keyof UnitFields, string>;
  value: () => unknown;
  color?: string | undefined;
};

export default Vue.extend({
  name: 'UnitHistoryList',

  props: {
    thingId: {
      type: String,
      required: true,
    },
    unitId: {
      type: String,
      required: true,
    },
    history: {
      type: Array,
      required: true,
      default: () => [],
    } as PropOptions<Unit[]>,
  },

  components: {
    UnitHistoryListItem,
  },

  data() {
    return {
      thing: Thing.empty(),
      unit: Unit.empty(),
    };
  },

  async created(): Promise<void> {
    await this.loadThing();
  },

  computed: {
    hasHistory(): boolean {
      return this.history.length > 0;
    },
    items(): ListItem[] {
      const items: ListItem[] = [
        {
          property: 'hx',
          value: () => this.unit?.hx?.value,
          color: '#39aa0d',
        },
        {
          property: 'sfp',
          value: () => this.unit?.sfp?.value,
          color: '#0064b3',
        },
        {
          property: 'supplyAir',
          value: () => this.unit?.supplyAir?.value,
          color: '#cc1f22',
        },
        {
          property: 'extractAir',
          value: () => this.unit?.extractAir?.value,
          color: '#f3ce3c',
        },
        {
          property: 'supplyAirDuct',
          value: () => this.unit?.supplyAirDuct?.value,
          color: '#18b8d7',
        },
        {
          property: 'extractAirDuct',
          value: () => this.unit?.extractAirDuct?.value,
          color: '#277d32',
        },
        {
          property: 'supplyAirTemperature',
          value: () => this.unit?.supplyAirTemperature?.value,
          color: '#39aa0d',
        },
        {
          property: 'extractAirTemperature',
          value: () => this.unit?.extractAirTemperature?.value,
          color: '#0064b3',
        },
        {
          property: 'outdoorAirTemperature',
          value: () => this.unit?.outdoorAirTemperature?.value,
          color: '#cc1f22',
        },
        {
          property: 'heatPowerRecovery',
          value: () => this.unit?.heatPowerRecovery?.value,
          color: '#f3ce3c',
        },
        {
          property: 'coolPowerRecovery',
          value: () => this.unit?.coolPowerRecovery?.value,
          color: '#18b8d7',
        },
        {
          property: 'ahuEnergyConsumption',
          value: () => this.unit?.ahuEnergyConsumption?.value,
          color: '#277d32',
        },
        {
          property: 'heatCoolEnergyConsumption',
          value: () => this.unit?.heatCoolEnergyConsumption?.value,
          color: '#39aa0d',
        },
      ];

      return items;
    },
    visibleItems(): ListItem[] {
      return this.items.filter(item => {
        const unitForProperty = this.history.find(
          entry => entry?.[item.property]
        );

        return isDefined(item.value()) && isDefined(unitForProperty);
      });
    },
  },

  methods: {
    async loadThing(forceRefresh = false): Promise<void> {
      const thing = await ThingsService.getThing(this.thingId, forceRefresh);
      this.thing = thing;

      const unit = thing.units?.find(unit => unit.id === this.unitId);
      if (unit) {
        const definedUnit = pickDefined(unit);
        this.unit = new Unit({ ...this.unit, ...definedUnit });
      }
    },
  },
});
