












































































import { DateTime } from 'luxon';
import Vue from 'vue';
import { historyIntervals } from '../../utils/UnitUtils';

const DEFAULT_FROM_DATE = DateTime.now()
  .minus({ days: 7 })
  .toISODate();
const DEFAULT_TO_DATE = DateTime.now().toISODate();

export default Vue.extend({
  name: 'UnitHistoryFilter',

  watch: {
    toDate(): void {
      this.emitFilter();
    },
    fromDate(): void {
      this.emitFilter();
    },
    interval(): void {
      this.emitFilter();
    },
  },

  mounted(): void {
    this.emitFilter();
  },

  data() {
    return {
      isFromDateMenuOpen: false,
      isToDateMenuOpen: false,

      fromDate: DEFAULT_FROM_DATE,
      toDate: DEFAULT_TO_DATE,

      interval: historyIntervals[0].value,
      intervals: historyIntervals,
    };
  },

  methods: {
    onInputFromDate(): void {
      this.isFromDateMenuOpen = false;
    },
    onInputToDate(): void {
      this.isToDateMenuOpen = false;
    },
    emitFilter(): void {
      this.$emit('filter', {
        toDate: this.toDate,
        fromDate: this.fromDate,
        interval: this.interval,
      });
    },
  },
});
