/* eslint-disable @typescript-eslint/camelcase */
import { AxiosInstance } from '../../api/axios';
import { CLOUD_REST_API_BASE_URL } from '../../config';
import { Unit } from '../../models/Unit';
import { pickDefined } from '../../utils/Utils';
import { AuthService } from '../auth/AuthService';
import { CacheService } from '../cache/CacheService';
import { HistoryServiceInterface } from './HistoryServiceInterface';
import { GetHistoryOptions } from './models/GetHistoryOptions';

const AGGREGATION_PROPS = [
  'aeckwh',
  'aecmwh',
  'cprkw',
  'cprw',
  'ea',
  'ead',
  'eat',
  'hceckwh',
  'hcecmwh',
  'hprkw',
  'hprw',
  'hx',
  'oat',
  'sa',
  'sad',
  'sat',
  'sfp',
];

class HistoryServiceImplementation implements HistoryServiceInterface {
  private cacheNamespace = 'history';

  private async _getHistory(
    thingId: string,
    unitId: string,
    options: GetHistoryOptions = {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> {
    const ENDPOINT = '/observations/find';
    const { data } = await AxiosInstance.post(
      `${CLOUD_REST_API_BASE_URL}${ENDPOINT}`,
      {
        query: {
          size: 1,
          sort: [{ timestamp: { order: 'asc' } }],
          query: {
            trackScores: false,
            bool: {
              must: [
                { match: { thingName: { query: `${thingId}__${unitId}` } } },
                {
                  range: {
                    timestamp: {
                      gte: options.fromDateInMs,
                      lte: options.toDateInMs,
                    },
                  },
                },
              ],
              filter: [
                {
                  bool: {
                    should: AGGREGATION_PROPS.map(prop => ({
                      exists: { field: `state.${prop}` },
                    })),
                  },
                },
              ],
            },
          },
          aggs: {
            history: {
              date_histogram: {
                field: 'timestamp',
                interval: options.interval ?? '1h',
                min_doc_count: 1,
              },
              aggs: {
                item: { top_hits: { size: 1, _source: {} } },
                ...AGGREGATION_PROPS.reduce(
                  (previous, prop) => ({
                    ...previous,
                    [prop]: { avg: { field: `state.${prop}` } },
                  }),
                  {}
                ),
              },
            },
          },
        },
      },
      { headers: { authorization: AuthService.getToken() } }
    );

    return data?.aggregations?.history?.buckets ?? [];
  }

  async getHistory(
    thingId: string,
    unitId: string,
    options?: GetHistoryOptions
  ): Promise<Unit[]> {
    const cacheKey = JSON.stringify({ unitId, ...options });
    const cachedUnits = CacheService.get<Unit[]>(this.cacheNamespace, cacheKey);

    if (cachedUnits && !options?.forceUpdate) {
      return cachedUnits;
    } else {
      const history = await this._getHistory(thingId, unitId, options);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const units = history.map((entry: Record<string, any>) => {
        const parameters = Object.entries(entry).reduce(
          (previous, [key, data]) => ({ ...previous, [key]: data?.value }),
          {}
        );
        const data = entry?.item?.hits?.hits?.pop() ?? {};
        const state = { ...data?._source, ...data?._source?.state } ?? {};

        return Unit.fromThingUnit(
          { ...pickDefined(state), ...pickDefined(parameters) },
          unitId
        );
      });

      CacheService.set<Unit[]>(this.cacheNamespace, cacheKey, units);

      return units;
    }
  }
}

export const HistoryService = new HistoryServiceImplementation();
