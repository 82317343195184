



























import { DateTime } from 'luxon';
import Vue from 'vue';
import PageBreadcrumbs from '../../components/app/PageBreadcrumbs.vue';
import UnitHistoryFilter from '../../components/units/UnitHistoryFilter.vue';
import UnitHistoryList from '../../components/units/UnitHistoryList.vue';
import { Unit } from '../../models/Unit';
import { HistoryService } from '../../services/history/HistoryService';
import { LoadingService } from '../../services/loading/LoadingService';
import { Optional } from '../../utils/Optional';

type HistoryFilter = {
  fromDate?: Optional<string>;
  interval?: Optional<string>;
  toDate?: Optional<string>;
};

export default Vue.extend({
  name: 'UnitHistoryPage',

  props: {
    thingId: {
      type: String,
      required: true,
    },
    unitId: {
      type: String,
      required: true,
    },
  },

  components: {
    PageBreadcrumbs,
    UnitHistoryFilter,
    UnitHistoryList,
  },

  data() {
    return {
      history: new Array<Unit>(),
      filter: {} as HistoryFilter,
    };
  },

  async created(): Promise<void> {
    LoadingService.setIsLoading(true);

    await this.loadHistory();

    LoadingService.setIsLoading(false);
  },

  methods: {
    async loadHistory(): Promise<void> {
      const fromDateInMs = this.filter.fromDate
        ? DateTime.fromISO(this.filter.fromDate)
            .startOf('day')
            .toMillis()
        : undefined;
      const toDateInMs = this.filter.toDate
        ? DateTime.fromISO(this.filter.toDate)
            .endOf('day')
            .toMillis()
        : undefined;
      const interval = this.filter.interval ? this.filter.interval : '1h';

      const history = await HistoryService.getHistory(
        this.thingId,
        this.unitId,
        {
          fromDateInMs,
          toDateInMs,
          interval,
        }
      );
      this.history = [...history];
    },
    async onFilterHistory(filter: HistoryFilter): Promise<void> {
      this.filter = { ...filter };

      LoadingService.setIsLoading(true);
      await this.loadHistory();
      LoadingService.setIsLoading(false);
    },
  },
});
